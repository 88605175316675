import React from 'react';
import { graphql } from 'gatsby';

import Metadata from '../components/Metadata';
import PageLayout from '../layouts/Page';
import Hero from '../components/Hero';
import PancakesStack from '../components/PancakesStack';
import SanityPortableText from '../components/SanityPortableText';
import CustomerQuote from '../components/CustomerQuote';
import Card from '../components/Card';
import Button from '../components/Button';

import './case-study.scss';

export const query = graphql`
  query CaseStudyPageQuery($id: String!) {
    caseStudy: sanityCaseStudy(_id: { eq: $id }) {
      _id
      company {
        name
        logoFullColorLight {
          asset {
            url
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
      pdfFile {
        asset {
          url
        }
      }
      customerQuotes {
        quote {
          quote
          author {
            name
            jobtitle
            image {
              ...ImageWithPreview
            }
            company {
              name
            }
          }
        }
      }
      _rawSidebar(resolveReferences: { maxDepth: 1000 })
      pancakes {
        ...SanityPancakeText
        ...SanityPancakeCards
        ...SanityPancakeTextImage
        ...SanityPancakePreFooter
        ...SanityPancakeFeaturesHighlight
        ...SanityPancakeCustomerQuote
        ...SanityPancakeCustomerQuotes
        ...SanityPancakeExplore
        ...SanityPancakeVideo
        ...SanityTrySourceControlProvider
        ...SanityPancakeLogos
        ...SanityPancakeJobs
        ...SanityTeamPancake
        ...SanitySpeakersPancake
        ...SanityPancakeSchedule
        ...SanityPancakeHubspotForm
        ...SanityBlogPostsListing
        ...SanityTabs
      }
    }
  }
`;

const CaseStudyPage = (props) => {
  const { caseStudy } = props.data;

  const logo = caseStudy.company?.logoFullColorLight?.asset.url;

  return (
    <PageLayout>
      <Metadata
        title={caseStudy.seo?.metaTitle}
        description={caseStudy.seo?.metaDescription}
        image={caseStudy.seo?.ogImage?.asset.url || null}
      />
      <Hero>
        <Hero.Title as="p">
          <span>Case Study</span>
          {!logo && caseStudy.company.name}
        </Hero.Title>
        {logo && (
          <Hero.Text>
            <img src={logo} alt="" />
          </Hero.Text>
        )}
      </Hero>
      {caseStudy._rawSidebar ? (
        <div className="CaseStudiesGrid">
          <aside>
            <Card
              theme="LightBlue"
              shadowLevel="Low"
              className="CaseStudiesSidebarInfos"
            >
              <SanityPortableText blocks={caseStudy._rawSidebar} />
              {caseStudy.pdfFile?.asset.url && (
                <Button to={caseStudy.pdfFile.asset.url}>Download PDF</Button>
              )}
            </Card>
            {caseStudy.customerQuotes.quote.map((quote) => (
              <Card
                className="CaseStudiesGridCustomerQuote"
                theme="Light"
                shadowLevel="Low"
                paddingSize="Double"
              >
                <CustomerQuote author={quote.author}>
                  {quote.quote}
                </CustomerQuote>
              </Card>
            ))}
          </aside>
          <PancakesStack pancakes={caseStudy.pancakes} />
        </div>
      ) : (
        <PancakesStack pancakes={caseStudy.pancakes} />
      )}
    </PageLayout>
  );
};

export default CaseStudyPage;
